<template>
	<div>

        <h1 class="text-primary mt-5">Marketing</h1>
        <vs-divider />
        
        <div v-if="!showMarketStats">
            <vs-row class="my-12">
                <vs-col vs-xs="12" vs-align="center" vs-type="flex" vs-justify="center">
                    <vs-button @click="showMarkStats"> Afficher les statistiques marketing </vs-button>
                </vs-col>
            </vs-row>
        </div>
        <div v-else>
            <br/>
            <StatEssaiPro ref="statEssaiPro" v-bind:month="select_date.getMonth()" />
        </div>

        <MarketingBtn />

        <br/>
        <br/>

        <h1 class="text-primary mt-5">Taux de transformation</h1>
        <vs-divider />
        <div v-if="!showTransfoStats">
            <vs-row class="my-12">
                <vs-col vs-xs="12" vs-align="center" vs-type="flex" vs-justify="center">
                    <vs-button @click="showTransfoStats=true"> Afficher les statistiques de transformation </vs-button>
                </vs-col>
            </vs-row>
        </div>
        <div v-else>
            <vs-row>
                <vs-col  vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="10" class="pl-3">
                    <cDate month="1" v-model="select_date" class="w-full select-large"/> 
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="2">
                    <vs-button @click="UpdateTaux"> Mettre à jour </vs-button>
                </vs-col>
            </vs-row>
            <br/>
            <Taux ref="taux"/>
        </div>
        <br/>
		<br/>

        <h1 class="text-primary mt-5">Votre clientèle</h1>
        <vs-divider />
        <div v-if="!showCustomersStats">
            <vs-row class="my-12">
                <vs-col vs-xs="12" vs-align="center" vs-type="flex" vs-justify="center">
                    <vs-button @click="showCustomersStats=true"> Afficher les statistiques clients </vs-button>
                </vs-col>
            </vs-row>
        </div>
        <div v-else>
            <vs-row>
                <vs-col vs-xs="12" vs-sm="12" vs-lg="5" class="espace-left">
                    <StatType  class="mb-3" />
                    <StatGender class="mt-3" />
                </vs-col>
                <vs-col vs-xs="12" vs-sm="12" vs-lg="7" class="espace-right">
                    <StatCity ref="StatCity" :long="5" />
                </vs-col>
            </vs-row>
            <br/>
            
        </div>

        <br/>
		<br/>
        <h1 class="text-primary mt-5">Raisons de non-contractualisation</h1>
        <vs-divider />
        <div v-if="!showRelances">
            <vs-row class="my-12">
                <vs-col vs-xs="12" vs-align="center" vs-type="flex" vs-justify="center">
                    <vs-button class="mr-3" @click="showRelances=true"> Afficher les relances </vs-button>
                    <vs-button v-on:click="toPage('/admin/historiquerelances')">Afficher l'historique des relances</vs-button>
                </vs-col>
            </vs-row>
        </div>
        <div v-else>
            <Relances :home="true" />
            <br/>
            
        </div>

	</div>
</template>

<style lang="scss" scoped>
@media only screen and (min-width: 900px)
{
    .espace-left
    {
        padding-right:10px;
    }
    .espace-right
    {
        padding-left:10px;
    }
}
@media only screen and (max-width: 899px)
{
    .espace-left
    {
        padding-right:0px;
        margin-bottom:20px;
    }
    .espace-right
    {
        padding-left:0px;
    }
}
</style>


<script>
import cDate from '@/components/app/date'
import StatEssaiPro from '@/components/stats/pessai.vue'
import StatBookYear from '@/components/stats/bookingYear.vue'
import StatBookDay from '@/components/stats/bookingDay.vue'

import StatCity from '@/components/stats/city.vue'
import StatGender from '@/components/stats/gender.vue'
import StatType from '@/components/stats/type.vue'

import MarketingBtn from '@/components/stats/components/graphAccueil/marketing.vue'
import StatMemberMonth from '@/components/stats/components/graphAccueil/statTransfMonth.vue'
import StatMemberYear from '@/components/stats/components/graphAccueil/statTransfYear.vue'
import Taux from '@/components/stats/components/graphAccueil/tauxT.vue'
import Relances from '@/components/stats/components/relances/relances.vue'

export default {
    components:{
        cDate,
        StatEssaiPro,
        StatBookYear,
        StatBookDay,
        MarketingBtn,
        StatMemberMonth,
        StatMemberYear,
        Taux,
        StatCity,
		StatGender,
		StatType,
        Relances
    },
    data(){
        return{
            select_date : new Date(),
            showCustomersStats: false,
            showTransfoStats: false,
            showMarketStats: true,
            showRelances: false
        }
    },
    watch:{
        select_date()
        {
            this.UpdateStatsEssai()
            // this.UpdateStatsBookYear();
            // this.UpdateStatsBookDay();
            this.UpdateStatTransformationMonth();
        },
    },
    methods:{
        // Redirect

		toPage( url )
		{
			this.$router.push(url ).catch(() => {});
		},
        UpdateStatsEssai()
        {
            this.$refs.statEssaiPro.setMonth( this.select_date.getMonth() );
            this.$refs.statEssaiPro.setYear( this.select_date.getFullYear() );
            this.$refs.statEssaiPro.refresh();
        },

        UpdateStatsBookYear()
        {
            this.$refs.statBookYear.setYear( this.select_date.getFullYear() );
            this.$refs.statBookYear.update();
        },

        UpdateStatsBookDay()
        {
            this.$refs.statBookDay.setMonth( this.select_date.getMonth() );
            this.$refs.statBookDay.setYear( this.select_date.getFullYear() );
            this.$refs.statBookDay.update();
        },

        UpdateStatTransformationMonth(){
            // this.$refs.statTransfMonth.setDate(this.select_date)
            // this.$refs.statTransfYear.setDate(this.select_date)
            this.$refs.taux.UpdateStatTransformationMonth(this.select_date)
        },

         UpdateTaux(){
             this.$refs.taux.UpdateTaux(this.select_date)
            
         }
    }
};


</script>