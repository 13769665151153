<template>
    <vs-row vs-type="flex" vs-justify="space-around" class="mt-5">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-lg="3" vs-md="4" class="my-3">  
            <vs-button color="primary" type="filled" class="w-full" v-on:click="toPage('/admin/option/conseils')">Conseils Marketing</vs-button><br/>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-lg="3" vs-md-w="4" class="my-3">  
            <vs-button v-if="studio.ga" color="primary" type="filled" class="w-full" :href="studio.ga" target="_blank">Résultats Google ADS</vs-button>
            <vs-button v-else color="primary" type="filled" class="w-full" v-on:click="toPage('/admin/option/externe')">Ajouter un raccourci Google ADS</vs-button>
            <br/>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-lg="3" vs-md-w="4" class="my-3">  
            <vs-button color="primary" type="filled" class="w-full" v-on:click="toPage('/admin/marketing/fitnessboost')"><img src="@/assets/images/fitnessboost/fitness-boost-logo.png" alt="Logo Fitness Boost" style="max-width:110px;" class="px-1"></vs-button>
        </vs-col>
    </vs-row>
</template>

<style lang="scss">

</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

import Studio from '@/database/models/studio'

export default {
    data(){
        return{
            studio:{
                fb: null,
                ga: null
            },
            getConseils: false,
        }
	},
	mounted:function()
	{
        //autoRefresh
        autoRefresh.SetCallback(()=>
        {
            //refresh
            Studio.getAllMemory((list_studio)=>
            {
                //find actual studio
                let studio = JSON.parse(JSON.stringify( list_studio[ this.$srvApi.getStudio() ] ));
                this.studio.fb = studio.fb
                this.studio.ga = studio.ga
            })
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
        toPage( url )
		{
			this.$router.push(url ).catch(() => {});
		}
    }
};

</script>