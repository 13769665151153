<template>
    <vx-card>
        <h2>Provenance Essais de {{ tyear }}</h2>
        <br/>
        <vue-apex-charts ref="charts" type="area" height="350" :options="lineAreaChartSpline.chartOptions" :series="lineAreaChartSpline.series"></vue-apex-charts>
    </vx-card>
</template>

<style lang="scss" scoped>
.title{
    margin-bottom:20px;
}
</style>

<script>
import VueApexCharts from 'vue-apexcharts'

//import Vue from 'vue'
import Fdate from '@/assets/utils/fdate'
import Channel  from '@/database/models/channel'
import Channel_link  from '@/database/models/channel_link'

export default {
    components:{
        VueApexCharts
    },
    data(){
        return{
            tyear : (new Date()).getFullYear(),
            tmonth: (new Date()).getMonth(),

            themeColors: [],
            lineAreaChartSpline:
            {
                series: [],
                chartOptions:
                {
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    colors: [],
                    xaxis: {
                        categories: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
                    },
                    tooltip: {
                        x: {
                            formatter: function(){
                                return "Provenance"
                            }
                        }
                    }
                }
            }
        }
    },
    watch:{
        $route (){
            
        },
    },
    mounted:function()
	{
        this.refresh();
    },
    methods:{
        getMonth( value )
        {
            let tab = Fdate.getTabMonth();
            return tab[ value ];
        },

        setMonth( value )
        {
            this.tmonth = value;
        },

        setYear( value )
        {
            this.tyear = value;
        },

        refresh()
        {
            Channel.getTabAllMemory((channel)=>
            {
                //reset
                this.lineAreaChartSpline.series = [];
                this.themeColors = [];
                this.lineAreaChartSpline.colors = [];
                //Create data info
                for( var i=0; i<channel.length; i++)
                {
                    var buffer = channel[i];

                    this.lineAreaChartSpline.series.push({
                        uid  : buffer.uid,
                        name : buffer.name,
                        data : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    })

                    this.themeColors.push( buffer.color )
                    this.lineAreaChartSpline.chartOptions.colors.push( buffer.color )
                }

                //Remplissage
                Channel_link.getTabAllMemory((list)=>
                {
                    for( var i=0; i<list.length; i++)
                    {
                        var buffer = list[i];
                        var da = new Date(buffer.createAt)
                        if( da.getFullYear() == this.tyear )
                        {
                            let month = da.getMonth();
                            for( var p=0; p<this.lineAreaChartSpline.series.length; p++ )
                            if( this.lineAreaChartSpline.series[p].uid == buffer.channel )
                                this.lineAreaChartSpline.series[p].data[month]++;

                        }
                    }
                    //console.log( this.lineAreaChartSpline )
                    this.$refs.charts.refresh();
                });
            });
        },
    }
}

</script>